var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},_vm._l((_vm.knownAddressOptions),function(knownAddressOption,knownAddressOptionIndex){return _c('CRSelect',{key:`known-address-selector-${knownAddressOptionIndex}-${
      knownAddressOption ? knownAddressOption.length : 0
    }`,staticClass:"w-full",attrs:{"id":"`known-address-selector-${knownAddressOptionIndex}`","value":_vm.selectedKnownAddresses[knownAddressOptionIndex],"items":knownAddressOption,"return-object":"","hide-details":"","placeholder":knownAddressOptionIndex === 0
        ? 'Select from existing known addresses'
        : 'Select sub-address (optional)',"item-text":"title","clearable":"","no-data-text":knownAddressOptionIndex > 0
        ? 'No known sub-addresses found at this level'
        : 'No known addresses found'},on:{"input":function($event){return _vm.selectKnownAddress($event, knownAddressOptionIndex)},"click:clear":function($event){return _vm.clearSelection(knownAddressOptionIndex)}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"margin-y-2"}),_c('v-list-tile',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.addNew(knownAddressOptionIndex)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"text-primary font-bold"},[_vm._v(" + Add New ")])],1)],1)]},proxy:true}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }