<template>
  <div class="w-full">
    <CRSelect
      v-for="(knownAddressOption,
      knownAddressOptionIndex) in knownAddressOptions"
      id="`known-address-selector-${knownAddressOptionIndex}`"
      :key="`known-address-selector-${knownAddressOptionIndex}-${
        knownAddressOption ? knownAddressOption.length : 0
      }`"
      :value="selectedKnownAddresses[knownAddressOptionIndex]"
      :items="knownAddressOption"
      return-object
      hide-details
      :placeholder="
        knownAddressOptionIndex === 0
          ? 'Select from existing known addresses'
          : 'Select sub-address (optional)'
      "
      class="w-full"
      item-text="title"
      clearable
      :no-data-text="
        knownAddressOptionIndex > 0
          ? 'No known sub-addresses found at this level'
          : 'No known addresses found'
      "
      @input="selectKnownAddress($event, knownAddressOptionIndex)"
      @click:clear="clearSelection(knownAddressOptionIndex)"
    >
      <template #append-item>
        <v-divider class="margin-y-2" />
        <v-list-tile
          class="cursor-pointer"
          @click="addNew(knownAddressOptionIndex)"
        >
          <v-list-tile-content>
            <v-list-tile-title class="text-primary font-bold">
              + Add New
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!-- <v-list-tile v-if="isBottomLevelKnownAddress" class="cursor-pointer" @click="addNewChild">
            <v-list-tile-content>
              <v-list-tile-title class="text-primary font-bold">+ Add New Child</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile> -->
      </template>
    </CRSelect>
  </div>
</template>
<script>
import { deepClone } from '@/utils/deepClone'
import { flattenKnownAddresses } from '@/utils/knownAddress'

export default {
  props: {
    value: { type: Object, required: false, default: () => {} },
    knownAddresses: { type: Array, required: true },
    mode: { type: String, required: true },
  },
  data() {
    return {
      knownAddressOptions: [],
      selectedKnownAddresses: [],
    }
  },
  computed: {
    flattenedKnownAddresses() {
      if (!this.knownAddresses || this.knownAddresses.length === 0) {
        return []
      }
      return flattenKnownAddresses(this.knownAddresses)
    },
    isBottomLevelKnownAddress() {
      if (!this.value) {
        return false
      }
      const children = this.value?.childKnownAddresses
      return !children || children.length === 0
    },
  },
  watch: {
    knownAddresses: {
      handler: function (newVal) {
        if (!newVal) {
          this.knownAddressOptions = [[]]
          return
        }
        this.knownAddressOptions = [deepClone(newVal)]
      },
      deep: true,
      immediate: true,
    },
    value: {
      handler: function (newVal, oldVal) {
        if (newVal?.knownAddressId === oldVal?.knownAddressId) {
          return
        }
        this.handleKnownAddressChange()
      },
      deep: true,
      immediate: true,
    },
    mode: {
      handler: function (newVal, oldVal) {
        if (newVal !== 'view') {
          return
        }
        this.handleKnownAddressChange()
      },
    },
  },
  methods: {
    addNew(knownAddressOptionIndex) {
      if (knownAddressOptionIndex === 0) {
        this.$emit('add', null)
        return
      }
      const parentKnownAddressId =
        this.selectedKnownAddresses[knownAddressOptionIndex - 1]
          ?.knownAddressId || null
      this.$emit('add', parentKnownAddressId)
    },
    selectKnownAddress(selectedKnownAddress) {
      if (!selectedKnownAddress) {
        return
      }
      this.$emit('input', selectedKnownAddress)
    },
    sortByTitleAlphabetically(list) {
      list.sort((a, b) => {
        const titleA = a.title.toLowerCase()
        const titleB = b.title.toLowerCase()
        if (titleA < titleB) {
          return -1
        }
        if (titleA > titleB) {
          return 1
        }
        return 0
      })
    },
    clearSelection(selectedKnownAddressIndex) {
      this.knownAddressOptions.splice(selectedKnownAddressIndex + 1)
      this.selectedKnownAddresses.splice(selectedKnownAddressIndex)
      const mostSpecificSelected =
        this.selectedKnownAddresses[this.selectedKnownAddresses.length - 1] ||
        null
      this.$emit('input', mostSpecificSelected)
    },
    buildSelectionsBySelectionPath(selectedPath) {
      if (!this.knownAddresses || this.knownAddresses?.length === 0) {
        return
      }
      this.selectedKnownAddresses = selectedPath.map((kaId) =>
        this.flattenedKnownAddresses.find((ka) => ka.knownAddressId === kaId)
      )
      this.knownAddressOptions = [
        [...this.knownAddresses],
        ...this.selectedKnownAddresses
          .map((ka) => ka?.childKnownAddresses)
          .filter((ka) => !!ka),
      ]
    },
    handleKnownAddressChange() {
      const selectedKnownAddresses = []
      const knownAddressOptions = []
      if (!this.value) {
        this.selectedKnownAddresses = selectedKnownAddresses
        this.knownAddressOptions = knownAddressOptions
        return
      }

      let knownAddress = deepClone(this.value)
      const selectedPath = []
      selectedPath.unshift(knownAddress.knownAddressId)
      let parentKnownAddressId = knownAddress?.parentKnownAddressId

      while (parentKnownAddressId) {
        knownAddress = this.flattenedKnownAddresses.find(
          (ka) => ka.knownAddressId === parentKnownAddressId
        )
        selectedPath.unshift(knownAddress.knownAddressId)
        parentKnownAddressId = knownAddress?.parentKnownAddressId
      }
      this.buildSelectionsBySelectionPath(selectedPath)
    },
  },
}
</script>
