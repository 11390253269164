<template>
  <div class="known-address-sidebar">
    <div class="known-address-sidebar--content">
      <div class="d-flex align-end w-full" style="flex-direction: column">
        <KnownAddressSelector
          v-model="knownAddress"
          :known-addresses="knownAddresses"
          :mode="mode"
          @add="initializeKnownAddress($event)"
        />
        <v-divider v-if="!!knownAddress" class="w-full margin-y-6" />
      </div>
      <div v-show="!!knownAddress" class="position-relative">
        <template v-if="!isModeView">
          <div
            v-if="!isEditingCoordinates"
            key="edit"
            class="position-absolute top-12 right-12 w-32 h-32 background-primary z-5 align-center cursor-pointer"
            @click="isEditingCoordinates = true"
          >
            <CRIcon
              view-box="0 0 24 24"
              class="margin-l-1 margin-t-1"
              color="white"
            >
              edit
            </CRIcon>
          </div>
          <template v-else>
            <div
              key="check"
              class="position-absolute top-12 right-12 w-32 h-32 background-success z-5 align-center cursor-pointer"
              @click="confirmCoordinates"
            >
              <CRIcon
                view-box="0 0 24 24"
                class="margin-l-1 margin-t-1"
                color="white"
              >
                check
              </CRIcon>
            </div>
            <div
              key="close"
              class="position-absolute top-12 right-48 w-32 h-32 background-error z-5 align-center cursor-pointer"
              @click="revertCoordinates"
            >
              <CRIcon
                view-box="0 0 24 24"
                class="margin-l-1 margin-t-1"
                color="white"
              >
                close
              </CRIcon>
            </div>
          </template>
        </template>
        <GmapMap
          v-show="!loading"
          ref="gMap"
          class="min-h-300 w-full"
          :center="mapCenter"
          :options="options"
          :zoom="defaultZoom"
          map-type-id="roadmap"
        >
          <GmapMarker
            :position="markerPosition"
            :options="markerOptions"
            :draggable="isEditingCoordinates"
            @dragend="handleMarkerDragEnd"
          />
        </GmapMap>
      </div>
      <v-form v-if="!!knownAddress" ref="form">
        <div class="d-flex align-center margin-t-2">
          <CRInput
            v-model="knownAddress.lat"
            label="Latitude"
            placeholder="Latitude"
            :disabled="isModeView || !isEditingCoordinates"
            :hide-details="hideDetails"
            class="margin-r-1"
            :rules="[(val) => !!val || 'Latitude is Required']"
          />
          <CRInput
            v-model="knownAddress.lng"
            label="Longitude"
            placeholder="Longitude"
            :disabled="isModeView || !isEditingCoordinates"
            :hide-details="hideDetails"
            class="margin-l-1"
            :rules="[(val) => !!val || 'Longitude is Required']"
          />
        </div>
        <CRInput
          v-model="knownAddress.title"
          label="Title"
          placeholder="Title"
          :disabled="isModeView"
          :hide-details="hideDetails"
          :rules="[(val) => !!val || 'Title is Required']"
        />
        <CRInput
          v-model="knownAddress.street1"
          label="Street 1"
          placeholder="Street 1"
          :disabled="isModeView"
          :hide-details="hideDetails"
        />
        <CRInput
          v-model="knownAddress.street2"
          label="Street 2"
          placeholder="Street 2"
          :disabled="isModeView"
          :hide-details="hideDetails"
        />
        <CRInput
          v-model="knownAddress.city"
          label="City"
          placeholder="City"
          :disabled="isModeView"
          :hide-details="hideDetails"
        />
        <div class="d-flex align-center margin-t-2">
          <CRSelect
            v-model="knownAddress.state"
            :items="states"
            item-text="label"
            item-value="value"
            label="State"
            placeholder="State"
            :disabled="isModeView"
            class="margin-r-1"
            :hide-details="hideDetails"
          />
          <CRInput
            v-model="knownAddress.postalCode"
            label="Zip Code"
            placeholder="Zip Code"
            :disabled="isModeView"
            class="margin-l-1 w-120"
            :hide-details="hideDetails"
          />
        </div>
      </v-form>
    </div>
    <div class="known-address-sidebar--btn-spacer"></div>
    <div
      class="known-address-sidebar--btn-container position-fixed font-18 bottom-0 right-0 d-flex justify-end background-white"
      style="margin: 0 0 71px 0"
    >
      <v-btn
        id="known-address-sidebar-cancel-button"
        color="white"
        outline
        style="border-radius: 0; border: none"
        :class="{
          'known-address-sidebar--action-btn ': !isExistingKnownAddressSelected,
          'known-address-sidebar--action-half-btn': isExistingKnownAddressSelected,
        }"
        class="background-white text-error d-flex font-18 margin-a-0 padding-y-0 w-one-half font-weight-bold cursor-pointer"
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="isModeView && isExistingKnownAddressSelected"
        id="known-address-sidebar-edit-button"
        color="white"
        outline
        style="border-radius: 0; border: none"
        class="known-address-sidebar--action-half-btn background-white text-primary d-flex font-18 margin-a-0 padding-y-0 w-one-half font-weight-bold cursor-pointer"
        @click="mode = 'edit'"
      >
        Edit
      </v-btn>
      <v-btn
        v-if="knownAddress && isModeEdit"
        id="known-address-sidebar-revert-button"
        color="white"
        outline
        style="border-radius: 0; border: none"
        class="known-address-sidebar--action-half-btn background-white text-error d-flex font-18 margin-a-0 padding-y-0 w-one-half font-weight-bold cursor-pointer"
        :disabled="!doesKnownAddressHaveChanges || isEditingCoordinates"
        @click="revert"
      >
        Revert
      </v-btn>
    </div>
    <div
      class="known-address-sidebar--btn-container position-fixed font-18 bottom-0 right-0 d-flex justify-end background-white"
    >
      <v-btn
        v-if="isModeEdit"
        id="known-address-sidebar-save-changes-button"
        color="white"
        outline
        style="border-radius: 0; border: none"
        class="known-address-sidebar--action-btn text-primary d-flex position-fixed font-18 bottom-0 margin-a-0 padding-y-0 font-weight-bold cursor-pointer"
        :disabled="isEditingCoordinates || !doesKnownAddressHaveChanges"
        @click="saveChanges"
      >
        Save Changes
      </v-btn>
    </div>
    <v-btn
      v-if="!isModeEdit"
      id="known-address-sidebar-save-button"
      class="margin-a-0 known-address-sidebar--action-btn d-flex position-fixed font-18 text-white bottom-0 padding-y-0 font-weight-bold cursor-pointer"
      color="primary"
      style="border-radius: 0"
      :disabled="
        isEditingCoordinates || !isKnownAddressDifferentThanOriginalAddress
      "
      @click="save"
    >
      Save
    </v-btn>
  </div>
</template>
<script>
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import { gmapApi } from 'vue2-google-maps'
import { mapStyles } from './mapStyles.js'
import knownAddress from '@/services/knownAddress'
import { getStates } from '@/utils/states'
import KnownAddressSelector from '@/components/KnownAddressSelector'
import { flattenKnownAddresses } from '@/utils/knownAddress'

export default {
  components: { KnownAddressSelector },
  props: {
    address: { type: Object, required: true },
    tripIndex: { type: Number, required: true },
    stopIndex: { type: Number, required: true },
  },
  data() {
    return {
      mode: 'view',
      loading: true,
      saving: false,
      isEditingCoordinates: false,
      data: {},
      map: null,
      mapCenter: { lat: 40.7703066, lng: -111.8920475 },
      markerPosition: { lat: 0, lng: 0 },
      defaultZoom: 15,
      hideDetails: true,
      options: {
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: true,
        styles: mapStyles,
      },
      knownAddresses: [],
      knownAddress: null,
    }
  },
  computed: {
    ...authComputed,
    google: gmapApi,
    markerOptions() {
      if (this.loading) {
        return {}
      }
      const pinColor = this.isEditingCoordinates
        ? this.$cr.theme.primary
        : this.$cr.theme.black
      const pinSVG = `data:image/svg+xml;charset=UTF-8,
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="${encodeURIComponent(
          pinColor
        )}" stroke="none">
          <path d="M20,0C11.8,0,5.1,6.7,5.1,14.9c0,12.3,13.4,24.2,14,24.7c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.7-0.1,0.9-0.3
            c0.6-0.5,14-12.5,14-24.7C34.9,6.7,28.2,0,20,0z M20,19.1c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.9-4.1,4.1-4.1s4.1,1.9,4.1,4.1
	          C24.1,17.2,22.3,19.1,20,19.1z"/>
        </svg>`

      return {
        icon: {
          url: pinSVG,
          scaledSize: new google.maps.Size(32, 32),
        },
      }
    },
    isKnownAddressDifferentThanOriginalAddress() {
      return JSON.stringify(this.knownAddress) !== JSON.stringify(this.address)
    },
    doesKnownAddressHaveChanges() {
      const knownAddress = this.knownAddresses.find(
        (knownAddress) =>
          knownAddress.knownAddressId === this.knownAddress.knownAddressId
      )
      return JSON.stringify(knownAddress) !== JSON.stringify(this.knownAddress)
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isExistingKnownAddressSelected() {
      return !!this.knownAddress?.knownAddressId
    },
    states() {
      return getStates()
    },
  },
  watch: {
    'knownAddress.knownAddressId': {
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return
        }
        if (!this.isModeView && newVal !== null) {
          this.mode = 'view'
          this.isEditingCoordinates = false
        }
      },
    },
    'knownAddress.lat': {
      immediate: true,
      handler() {
        if (!this.knownAddress?.lat) {
          return
        }
        this.markerPosition.lat = parseFloat(this.knownAddress.lat)
        this.centerMapOnLatLng()
      },
    },
    'knownAddress.lng': {
      immediate: true,
      handler() {
        if (!this.knownAddress?.lng) {
          return
        }
        this.markerPosition.lng = parseFloat(this.knownAddress.lng)
        this.centerMapOnLatLng()
      },
    },
  },
  async mounted() {
    this.getKnownAddresses()
    this.loadMap()
  },
  methods: {
    revert() {
      const knownAddressId = this.knownAddress.knownAddressId
      const flattenedAddresses = flattenKnownAddresses(this.knownAddresses)
      const knownAddress = flattenedAddresses.find(
        (knownAddress) => knownAddress.knownAddressId === knownAddressId
      )
      this.knownAddress = deepClone(knownAddress)
    },
    buildAddressTitle() {
      let title = this.knownAddress?.title || ''
      if (!this.knownAddresses || !this.knownAddresses.length) {
        return title
      }
      const flattenedAddresses = flattenKnownAddresses(this.knownAddresses)
      let parentKnownAddressId = this.knownAddress?.parentKnownAddressId
      while (parentKnownAddressId) {
        const parentKnownAddress = flattenedAddresses.find(
          (address) => address.knownAddressId === parentKnownAddressId
        )
        title = `${parentKnownAddress.title} - ${title}`
        parentKnownAddressId = parentKnownAddress.parentKnownAddressId
      }
      return title
    },
    async save() {
      const compositeTitle = this.buildAddressTitle()
      this.hideDetails = true
      if (!this.$refs.form?.validate()) {
        this.hideDetails = false
        return
      }
      let knownAddressId = this.knownAddress.knownAddressId
      let addressName = this.knownAddress.name
      if (!knownAddressId) {
        const knownAddressResult = await this.createKnownAddress()
        const knownAddress = knownAddressResult.data.data
        knownAddressId = knownAddress.knownAddressId
        addressName = knownAddress.name
      }
      this.knownAddress.knownAddressId = knownAddressId
      this.knownAddress.addressName = addressName

      EventBus.$emit(
        `quote-form-trip-${this.tripIndex}-edit-stop-location`,
        { ...this.knownAddress, title: compositeTitle },
        this.stopIndex
      )
      this.close()
    },
    cancel() {
      if (!this.isModeEdit) {
        this.close()
        return
      }
      this.revert()
      this.mode = 'view'
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    handleMarkerDragEnd(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
    },
    centerMapOnLatLng() {
      this.mapCenter.lat = parseFloat(this.knownAddress.lat)
      this.mapCenter.lng = parseFloat(this.knownAddress.lng)
    },
    confirmCoordinates() {
      this.isEditingCoordinates = false
      this.knownAddress.lat = this.markerPosition.lat
      this.knownAddress.lng = this.markerPosition.lng
    },
    revertCoordinates() {
      this.isEditingCoordinates = false
      this.markerPosition.lat = parseFloat(this.knownAddress.lat)
      this.markerPosition.lng = parseFloat(this.knownAddress.lng)
    },
    async createKnownAddress() {
      const knownAddressPayload = deepClone(this.knownAddress)
      knownAddressPayload.name = this.buildAddressName(this.knownAddress)
      delete knownAddressPayload.addressName
      return knownAddress.create(knownAddressPayload)
    },
    async updateKnownAddress() {
      const knownAddressPayload = deepClone(this.knownAddress)
      knownAddressPayload.name = this.buildAddressName(this.knownAddress)
      delete knownAddressPayload.addressName
      return knownAddress.update(
        this.knownAddress.knownAddressId,
        knownAddressPayload
      )
    },
    buildAddressName(knownAddress) {
      const {
        street1,
        street2,
        city,
        state,
        postalCode,
        country,
      } = knownAddress
      let parts = []

      if (street1 && street1.trim() !== '') {
        parts.push(street1)
      }

      if (street2 && street2.trim() !== '') {
        parts.push(street2)
      }

      if (city && city !== '') {
        parts.push(city)
      }

      if (state && state !== '') {
        if (postalCode && postalCode !== '') {
          // If there is a postal code, concatenate state and postal code without comma
          parts.push(`${state} ${postalCode}`)
        } else {
          parts.push(state)
        }
      } else if (postalCode && postalCode !== '') {
        parts.push(postalCode)
      }

      if (country) {
        parts.push(country)
      }

      let name = parts.join(', ')

      return name
    },
    async getKnownAddressById() {
      const knownAddressId = this.address?.knownAddressId
      if (!knownAddressId) {
        return
      }
      const knownAddressResult = await knownAddress.byId(knownAddressId)
      this.knownAddress = knownAddressResult.data.data
    },
    async getKnownAddresses() {
      let lat, lng
      const knownAddressId = this.address?.knownAddressId
      if (!knownAddressId) {
        lat = this.address.lat
        lng = this.address.lng
      } else {
        const knownAddressResult = await knownAddress.topLevelParentById(
          knownAddressId
        )
        const topLevelParent = knownAddressResult.data.data
        lat = topLevelParent.lat
        lng = topLevelParent.lng
      }

      const knownAddressesResult = await knownAddress.parentsByDistanceFromLatLng(
        { lat, lng, distance: 0.1 }
      )
      let knownAddresses = knownAddressesResult.data.data
      this.knownAddresses = knownAddresses

      if (knownAddressId) {
        const flattenedAddresses = flattenKnownAddresses(this.knownAddresses)
        const knownAddress = flattenedAddresses.find(
          (knownAddress) => knownAddress.knownAddressId === knownAddressId
        )
        if (!knownAddress) {
          return
        }
        this.knownAddress = deepClone(knownAddress)
      }
    },
    async loadMap() {
      if (this.$refs.gMap == null) {
        return
      }

      this.map = await this.$refs.gMap.$mapPromise
      this.loading = false
    },
    initializeKnownAddress(parentKnownAddressId = null) {
      this.knownAddress = {
        ...deepClone(this.address),
        knownAddressId: null,
        parentKnownAddressId,
        title: parentKnownAddressId ? '' : `${this.address?.title}`,
      }
      this.mode = 'add'
      this.loadMap()
    },
    async saveChanges() {
      this.saving = true
      await this.updateKnownAddress()
      await this.getKnownAddresses()
      this.saving = false
      this.mode = 'view'
    },
    selectExistingKnownAddress(knownAddress) {
      this.knownAddress = deepClone(knownAddress)
      this.mode = 'view'
    },
  },
}
</script>

<style lang="scss" scoped>
.known-address-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px 48px;
  }

  &--btn-spacer {
    min-height: 142px;
    width: 100%;
  }

  &--action-btn {
    flex-direction: column;
    width: inherit;
    height: 71px;
    text-align: center;
  }

  &--action-half-btn {
    flex-direction: row;
    max-width: 250px;
    width: 50%;
    height: 71px;
    text-align: center;
  }

  &--btn-container {
    width: inherit;
    height: 71px;
    text-align: center;
  }
}
</style>
